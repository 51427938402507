import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Button,
  TextField,
  InputAdornment,
  Card,
  MenuItem,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "3.3vh 1.6vw",
    marginTop: "2vw",
  },
  label: {
    fontSize: "0.8vw",
    textAlign: "left",
    marginBottom: "0.4vw",
  },
  adorment: {
    color: "#808A93",
  },
  buttonCari: {
    fontSize: "0.9vw",
    height: "40px",
  },
  resetFilter: {
    color: theme.palette.secondary.main,
    fontSize: "0.9vw",
    fontWeight: "bold",
    marginTop: "1vw",
    cursor: "pointer",
  },
}));

const SearchComponent = ({
  onChange,
  valueChange,
  dataCategory,
  dataStatus,
}) => {
  const classes = useStyles();
  const [nama, setNama] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    setNama("");
    setSelectedCategory("");
    setSelectedStatus("");
  }, [valueChange]);

  const resetFilter = () => {
    setNama("");
    setSelectedCategory("");
    setSelectedStatus("");
    const dataReset = {
      category: "",
      status: "",
      search: "",
    };

    onChange(dataReset);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const dataFilter = {
      category: selectedCategory,
      status: selectedStatus,
      search: nama,
    };

    onChange(dataFilter);
  };

  return (
    <Card className={classes.card}>
      <form onSubmit={handleChange}>
        <Grid container spacing={5}>
          <Grid className={classes.grid} item xs={6}>
            <Typography className={classes.label}>
              Pencarian
            </Typography>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Pencarian Nama Pelamar/Posisi kerja"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search className={classes.adorment} />
                  </InputAdornment>
                ),
              }}
              value={nama}
              onChange={(e) => setNama(e.target.value)}
            />
            <Typography
              className={classes.resetFilter}
              onClick={() => resetFilter()}
            >
              Reset Filter
            </Typography>
          </Grid>
          <Grid className={classes.grid} item xs={2}>
            <Typography className={classes.label}>Kategori Lowongan</Typography>
            <TextField
              fullWidth
              hiddenLabel
              select
              size="small"
              variant="filled"
              InputProps={{
                disableUnderline: true,
              }}
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              SelectProps={{
                displayEmpty: true,
              }}
            >
              <MenuItem key="default-category" value="">
                Semua Kategori
              </MenuItem>
              {dataCategory.map((item, i) => (
                <MenuItem key={i} value={item.categoryName}>
                  {item.categoryName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {dataStatus !== undefined && (
            <Grid className={classes.grid} item xs={2}>
              <Typography className={classes.label}>Status</Typography>
              <TextField
                fullWidth
                hiddenLabel
                select
                size="small"
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem key="default-status" value="">
                  Semua Status
                </MenuItem>
                {dataStatus.map((item, i) => (
                  <MenuItem key={i} value={item.statusName}>
                    {item.statusName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid className={classes.grid} item xs={2}>
            <Typography className={classes.label}>&nbsp;</Typography>
            <Button
              className={classes.buttonCari}
              fullWidth
              variant="contained"
              color="secondary"
              type="submit"
              // onClick={() => handleChange()}
            >
              Cari
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

export default SearchComponent;
