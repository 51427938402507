import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import Common from "./Common";
import Auth from "./Auth";
import tabReducers from "./Tab";

const Reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    tab: tabReducers,
  });

export default Reducers;
