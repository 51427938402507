import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { AUTHENTICATION } from "../../../service";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "black",
    height: "100vh",
  },
  content: {
    minHeight: "calc(100% - 35.2vw)",
    paddingTop: "5vw",
    paddingBottom: "5vw",
    background: "#F3F9FE",
  },
  labelSpacing: {
    marginBottom: "3.3vh",
  },
  buttonLogin: {
    background: "#E21A43",
    height: 50,
  },
  labelLogin: {
    textTransform: "capitalize",
    color: "white",
  },
  labelCapitalize: {
    textTransform: "capitalize",
  },
  inputFilled: {
    background: "#F3F4F5 !important ",
  },
  loginTitle: {
    color: theme.palette.primary.main,
    fontSize: "1.8vw",
    fontWeight: "bold",
  },
  decoration1: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "30vh",
  },
  decoration2: { position: "absolute", bottom: 0, height: "30vh" },
  versionText: {
    color: theme.palette.primary.main,
    fontSize: "0.8vw",
    fontWeight: "bold",
  },
}));

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000,
});

const Login = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function login(e) {
    e.preventDefault();
    localStorage.clear();
    if (username === "") {
      Toast.fire({
        icon: "warning",
        title: "Username Tidak Boleh Kosong",
      });
      return;
    }
    if (password === "") {
      Toast.fire({
        icon: "warning",
        title: "Password Tidak Boleh Kosong",
      });
      return;
    }
    setIsLoading(true);
    var body = {
      username: username,
      password: password,
      roleId: 2,
    };
    AUTHENTICATION.login(body)
      .then((res) => {
        setIsLoading(false);
        // console.log("inidata");
        // console.log(res);
        if (res.status === 200 && res.data?.auth) {
          localStorage.setItem("token", res.data.auth.authorization);
          localStorage.setItem("tokenType", res.data.auth.tokenType);
          localStorage.setItem("userId", res.data.details.id);
          localStorage.setItem("userName", res.data.details.user_real_name);
          localStorage.setItem("userRoleId", res.data.details.jabatan_id);
          localStorage.setItem("userRoleName", res.data.details.jabatan);
          localStorage.setItem("userLevel", res.data.details.level_pangkat_id);
          // localStorage.setItem("userUnit", res.data.details.unit_kerja_id);
          localStorage.setItem("isLogin", true);
          history.push("/");
        } else {
          Toast.fire({
            icon: "warning",
            title: res.data.message,
          });
        }
      })
      .catch((res) => {
        setIsLoading(false);
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.message || "Username / Password Salah",
        });
      });
  }

  return (
    <form onSubmit={login}>
      <Grid
        className={classes.root}
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <Grid item xs={8}>
          <Box
            id="background-image"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/images/login/erekrut.jpeg"
              })`,
              backgroundSize: "cover",
              height: "100vh",
            }}
          />
        </Grid>
        <Grid item container xs={4}>
          <img
            alt="decoration-1"
            src={
              process.env.PUBLIC_URL + "/images/login/login-decoration-1.png"
            }
            className={classes.decoration1}
          />
          <img
            alt="decoration-2"
            src={
              process.env.PUBLIC_URL + "/images/login/login-decoration-2.png"
            }
            className={classes.decoration2}
          />
          <Grid item xs={12} container style={{ padding: "0 2.5vw" }}>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center" }}
              className={classes.labelSpacing}
            >
              <img
                alt="logo"
                src={process.env.PUBLIC_URL + "/images/logo.png"}
              />
            </Grid>
            <Grid item xs={12} className={classes.labelSpacing}>
              <Typography align="center" className={classes.loginTitle}>
                Login
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.labelSpacing}>
              <Typography variant="subtitle1">Username</Typography>
              <TextField
                placeholder="Masukan Username"
                variant="filled"
                hiddenLabel
                size="medium"
                fullWidth
                InputProps={{
                  className: classes.inputFilled,
                  disableUnderline: true,
                }}
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.labelSpacing}>
              <Typography variant="subtitle1">Password</Typography>
              <TextField
                placeholder="Masukan Password"
                variant="filled"
                hiddenLabel
                size="medium"
                fullWidth
                type="password"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                InputProps={{
                  className: classes.inputFilled,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.buttonLogin}
                variant="contained"
                disabled={isLoading}
                fullWidth
                color="secondary"
                type="submit"
                // onClick={() => {
                //   login();
                // }}
              >
                {isLoading ? <i className="fas fa-circle-notch fa-spin" /> : ""}
                <Typography className={classes.labelLogin}>Login</Typography>
              </Button>
            </Grid>
            <Grid
              style={{ textAlign: "center", padding: "2.5vh" }}
              item
              xs={12}
            >
              <Typography className={classes.versionText}>v.0.2.5</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Login;
