import { SET_CURRENT_TAB_STAFF, SET_CURRENT_TAB_SPV } from "../actions/Tab";

const initialState = {
  currentTabStaff: { label: "Pelamar Baru", step: 1 },
  currentTabSpv: { label: "Pelamar Baru", step: 1 },
};

export default function tabReducers(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_TAB_STAFF:
      return { ...state, currentTabStaff: { ...action.value } };
    case SET_CURRENT_TAB_SPV:
      return { ...state, currentTabSpv: { ...action.value } };
    default:
      return state;
  }
}
