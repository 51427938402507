import {
  FETCH_START,
  FETCH_MESSAGE,
  FETCH_CLOSE,
} from "../constants/ActionTypes";

const INIT_STATE = {
  loading: false,
  open: false,
  type: "",
  title: "",
  message: "",
};

const Common = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        loading: true,
        open: false,
        type: "",
        title: "",
        message: "",
        progress: 0,
      };
    }
    case FETCH_MESSAGE: {
      return { ...state, loading: false, open: true, ...action.payload };
    }
    case FETCH_CLOSE: {
      return {
        ...state,
        loading: false,
        open: false,
        type: "",
        title: "",
        message: "",
        progress: 0,
      };
    }
    default:
      return state;
  }
};

export default Common;
