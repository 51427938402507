import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "23vw",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "0.9vw",
    fontWeight: "bold",
  },
  contentLabel: {
    color: "black",
    whiteSpace: "pre-wrap",
    fontSize: "0.8vw",
  },
  boxContent: {
    padding: "2.5vw",
  },
}));

function ModalKonfirmasi({ visible, handleClose, onSubmit, loading }) {
  const classes = useStyles();
  return (
    <Modal open={visible}>
      <Box className={classes.root}>
        <Card style={{ borderRadius: 8 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ padding: "3.3vh 2.5vw" }}
          >
            <Typography className={classes.title}>
              Konfirmasi 
            </Typography>
            <CloseOutlined
              style={{ cursor: "pointer", color: "black" }}
              onClick={() => {
                !loading && handleClose();
              }}
            />
          </Grid>
          <Divider />
          <Box className={classes.boxContent}>
            <Typography className={classes.contentLabel}>
            Apakah anda yakin akan kebenaran data dan informasi ini dan akan meneruskan informasi ini  ke email Pelamar ?
            </Typography>
          </Box>
          <Divider />
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ padding: "3.3vh 2.5vw" }}
            spacing={2}
          >
            <Grid item>
              <Button
                color="secondary"
                style={{
                  borderRadius: 8,
                  backgroundColor: "#FFF1F4",
                  padding: "1.2vh 1vw",
                  border: "1px solid #E21A43",
                  fontSize: "0.7vw",
                  fontWeight: 600,
                }}
                onClick={handleClose}
                disabled={loading}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                style={{
                  borderRadius: 8,
                  padding: "1.2vh 2.5vw",
                  fontSize: "0.7vw",
                  fontWeight: 600,
                }}
                onClick={() => onSubmit()}
                disabled={loading}
              >
                {loading ? <i className="fas fa-circle-notch fa-spin" /> : ""}
                Submit
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Modal>
  );
}

export default ModalKonfirmasi;
