import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import CardDashboard from "../../../../../GlobalComponent/CardDashboard";
import { Toast } from "../../../../Component/Notification";
import { API } from "../../../../../service/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  content: {
    minHeight: "71vh",
    paddingTop: "19vh",
    padding: "0 5vw 10vh 5vw",
    background: "#F3F9FE",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.5vw",
    fontWeight: "bold",
    marginBottom: "3.3vh",
  },
}));

const DashboardSupervisor = () => {
  const classes = useStyles();
  const [dataDashboard, setDataDashboard] = useState({});

  useEffect(() => {
    getDashboard();
  }, []);

  const getDashboard = () => {
    API(`dashboard.getDashboardSupervisor`, {
      params: {
        levelPangkatId: parseInt(localStorage.getItem("userLevel")),
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setDataDashboard(res.data);
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data dashboard!",
        });
      });
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography className={classes.title}>Dashboard</Typography>
        <Grid container spacing={5}>
          <Grid item xs={3}>
            <CardDashboard
              data={{
                title: "Approval Pelamar",
                totalItems: dataDashboard?.totalApprovalPelamar,
                status: "Menunggu Approval",
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <CardDashboard
              data={{
                title: "Approval Dokumen",
                totalItems: dataDashboard?.totalApprovalDokumen,
                status: "Menunggu Approval",
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DashboardSupervisor;
