import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Grid,
  Card,
  Typography,
  makeStyles,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@material-ui/core";

// Import Component
import SearchComponent from "../../../../../GlobalComponent/Search";
import StatusChip from "../../../../../GlobalComponent/StatusChip";
import { Toast } from "../../../../Component/Notification";
import { API } from "../../../../../service/axios";
import Pagination from "../../../../../GlobalComponent/Pagination";
import { useHistory } from "react-router";
import { config } from "../../../../../config";

//redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setCurrentTabSpv } from "../../../../../redux/actions/Tab";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  content: {
    minHeight: "71vh",
    paddingTop: "19vh",
    padding: "0 5vw 10vh 5vw",
    background: "#F3F9FE",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.5vw",
    fontWeight: "bold",
  },
  card: {
    padding: "0 0.5vw",
    marginTop: "2vw",
  },
  grid: {
    padding: "1vw 0.5vw",
  },
  buttonActive: {
    padding: "1vw 0",
    fontSize: "1vw",
    fontWeight: "bold",
  },
  button: {
    padding: "1.1vw 0",
    fontSize: "1vw",
    fontWeight: "bold",
    color: theme.palette.primary.dark,
  },
  cardContentRoot: {
    borderRadius: 8,
    marginTop: 25,
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: "1.5vw",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  buttonTabs: {
    marginRight: 20,
    borderRadius: 8,
    padding: "1.2vh 2.5vw",
    fontSize: "0.7vw",
    fontWeight: 600,
  },
  buttonTabsDisabled: {
    backgroundColor: "#808A93",
    marginRight: 20,
    borderRadius: 8,
  },
  buttonTabs2: {
    borderRadius: 8,
    backgroundColor: "#FFF1F4",
    padding: "1.2vh 1vw",
    border: "1px solid #E21A43",
    fontSize: "0.7vw",
    fontWeight: 600,
    height: "4.8vh",
  },
  adorment: {
    color: "#808A93",
  },
  cardContentSearch: {
    padding: "3.3vh 1.6vw",
  },
  cardBtn: {
    justifyContent: "flex-end",
    display: "flex",
  },
  th: {
    fontSize: "0.9vw",
    fontWeight: "bold",
  },
  td: {
    fontSize: "0.625vw",
  },
}));

const RiwayatRekrutment = ({ currentTabSpv, setCurrentTabSpv }) => {
  const classes = useStyles();
  const tabs = [
    { label: "Pelamar Baru", step: 1 },
    { label: "Seleksi Administrasi", step: 2 },
    { label: "Psikotes & Tes Tulis", step: 3 },
    { label: "Wawancara", step: 4 },
    { label: "Pemeriksaan Medis (MCU)", step: 5 },
    { label: "Verifikasi Dokumen", step: 6 },
  ];
  const initialParams = {
    step: currentTabSpv.step,
    size: 10,
    page: 0,
    search: "",
    status: "",
    category: "",
  };
  const history = useHistory();
  const [dataPelamar, setDataPelamar] = useState({});
  const [params, setParams] = useState(initialParams);
  const [loading, setLoading] = useState(false);
  const [dataCategory, setDataCategory] = useState([]);
  const [dataStatus, setDataStatus] = useState([]);
  const [dataStatusSubmission, setDataStatusSubmission] = useState([]);
  const [dataStatusSubmissionDokumen, setDataStatusSubmissionDokumen] =
    useState([]);

  const getDataCategory = () => {
    API(`lov.getCategory`)
      .then((res) => {
        if (res.status === 200 && res.data?.Message === "Success") {
          setDataCategory(res.data?.Data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data kategori!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data kategori!",
        });
      });
  };

  const getDataStatus = () => {
    API(`lov.getStatus`)
      .then((res) => {
        if (res.status === 200 && res.data?.message === "success") {
          setDataStatus(res.data?.data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data status!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data status!",
        });
      });
  };

  const getDataStatusSubmission = () => {
    API(`lov.getStatusSubmission`)
      .then((res) => {
        if (res.status === 200 && res.data?.message === "success") {
          setDataStatusSubmission(res.data?.data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data status!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data status!",
        });
      });
  };

  const getDataStatusSubmissionDokumen = () => {
    API(`lov.getStatusSubmissionDokumen`)
      .then((res) => {
        if (res.status === 200 && res.data?.message === "success") {
          setDataStatusSubmissionDokumen(res.data?.data);
        } else {
          Toast.fire({
            icon: "warning",
            title: "Gagal mendapat data status!",
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: "Gagal mendapat data status!",
        });
      });
  };

  const getPelamar = useCallback(() => {
    setLoading(true);
    API(`pelamar.getPelamar`, {
      params,
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200 || res.data.Message === "Success") {
          setDataPelamar(res.data);
        }
      })
      .catch((res) => {
        setLoading(false);
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Gagal mendapat data pelamar!",
        });
      });
  }, [params]);

  const exportData = () => {
    API(`pelamar.exportFile`, {
      params: {
        step: currentTabSpv.step,
        position: "staff",
        levelPangkatId: parseInt(localStorage.getItem("userLevel")),
      },
      responseType: "blob",
      headers: {
        "Content-Type": "application/octet-stream",
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${currentTabSpv.label}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Export file gagal!",
        });
      });
  };

  const handleSearch = (val) => {
    setParams({
      ...initialParams,
      category: val.category,
      status: val.status,
      search: val.search,
      step: currentTabSpv.step,
    });
  };

  const handlePagination = (val) => {
    setParams({
      ...params,
      size: val.size,
      page: val.page - 1,
    });
  };

  useEffect(() => {
    getDataCategory();
    getDataStatus();
    getDataStatusSubmission();
    getDataStatusSubmissionDokumen();
  }, []);

  useEffect(() => {
    getPelamar();
  }, [getPelamar]);

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography className={classes.title}>E-Form</Typography>
        <Card className={classes.card}>
          <Grid container>
            {tabs.map((item, i) => (
              <Grid className={classes.grid} key={i} item xs={2}>
                <Button
                  className={
                    item.label === currentTabSpv.label
                      ? classes.buttonActive
                      : classes.button
                  }
                  fullWidth
                  color="secondary"
                  variant={
                    item.label === currentTabSpv.label ? "contained" : "text"
                  }
                  onClick={() => {
                    setCurrentTabSpv(item);
                    setDataPelamar([]);
                    setParams({ ...initialParams, step: item.step });
                  }}
                >
                  {item.label}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Card>
        <Box>
          <SearchComponent
            onChange={(val) => handleSearch(val)}
            valueChange={currentTabSpv}
            dataCategory={dataCategory}
            dataStatus={
              currentTabSpv.label === tabs[0].label
                ? dataStatusSubmission
                : currentTabSpv.label === tabs[5].label
                ? dataStatusSubmissionDokumen
                : dataStatus
            }
          />
          <Card className={classes.cardContentRoot}>
            <Grid container className={classes.cardContentSearch}>
              <Grid item xs={6}>
                <Typography className={classes.subtitle}>
                  {currentTabSpv.label}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.cardBtn}>
                <Button
                  className={classes.buttonTabs2}
                  onClick={() => exportData()}
                  color="secondary"
                  disabled={!dataPelamar?.data?.length}
                >
                  Export Excel
                </Button>
              </Grid>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={12}>
                <TableContainer>
                  {loading ? (
                    <Grid container justifyContent="center">
                      <CircularProgress style={{ margin: "5vw" }} />
                    </Grid>
                  ) : (
                    <>
                      {dataPelamar?.data?.length ? (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className={classes.th}
                                style={{ paddingLeft: "1.6vw" }}
                              >
                                Nama Pelamar
                              </TableCell>
                              <TableCell className={classes.th}>
                                Posisi Kerja
                              </TableCell>
                              <TableCell className={classes.th}>
                                Kategori Lowongan
                              </TableCell>
                              <TableCell className={classes.th}>
                                Domisili
                              </TableCell>
                              <TableCell className={classes.th}>
                                Dokumen Terlampir
                              </TableCell>
                              <TableCell className={classes.th}>
                                Status Seleksi
                              </TableCell>
                              <TableCell className={classes.th} align="center">
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dataPelamar?.data?.map((row, i) => (
                              <TableRow key={i}>
                                <TableCell
                                  className={classes.td}
                                  style={{ paddingLeft: "1.6vw" }}
                                >
                                  {row.name}
                                </TableCell>
                                <TableCell className={classes.td}>
                                  {row.positionName}
                                </TableCell>
                                <TableCell className={classes.td}>
                                  {row.categoryName}
                                </TableCell>
                                <TableCell className={classes.td}>
                                  {row.location}
                                </TableCell>
                                <TableCell className={classes.td}>
                                  <Button
                                    className={classes.buttonTabs2}
                                    variant="outlined"
                                    disabled={
                                      row.testResultsDocPath === null ||
                                      row.testResultsDocPath === ""
                                    }
                                    onClick={() => {
                                      window.open(
                                        `${config.BASE_URL}${row.testResultsDocPath}`,
                                        "_blank"
                                      );
                                    }}
                                  >
                                    Download Dokumen
                                  </Button>
                                </TableCell>
                                <TableCell className={classes.td}>
                                  <StatusChip value={row.testResultsStatus} />
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    className={classes.buttonTabs2}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                      history.push(
                                        `/sdi/supervisor/detail-pelamar/${row.applicantSubmissionId}`
                                      );
                                    }}
                                  >
                                    Lihat Detail
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      ) : (
                        <Typography
                          className={classes.td}
                          align="center"
                          style={{ margin: "2vw" }}
                        >
                          Data not found
                        </Typography>
                      )}
                      <Pagination
                        totalItem={dataPelamar.totalData}
                        pages={params.page}
                        size={params.size}
                        onChange={(val) => handlePagination(val)}
                      />
                    </>
                  )}
                </TableContainer>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  currentTabSpv: state.tab.currentTabSpv,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setCurrentTabSpv: setCurrentTabSpv }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RiwayatRekrutment);
