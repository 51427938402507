export const config = {
  BASE_URL:
    // process.env.REACT_APP_BASE_SERVICE_URL ||
    // `https://apis.cloudias79.com/bjbs-erec-svc-dev`
    // `https://erecruitment-apis-dev.bjbs.id`,
    // `http://localhost:8081`
    // `${window.location.protocol}/api`
    `http://172.31.202.79:8088`
};

