import axios from "axios";
import { config } from './../config';

let axiosRequestConfig = {
    headers: {
        'Content-Type': 'application/json'
    }
}
function login(data) {
    return axios.post(`${config.BASE_URL}/login`, data, axiosRequestConfig);
}

function lovCity() {
    return axios.get(`${config.BASE_URL}/rest/talent/open/city/find-all`, axiosRequestConfig);
}

function register(data) {
    return axios.post(`${config.BASE_URL}/rest/talent/open/users/registerApplicant`, data, axiosRequestConfig);
}

export {
    login,
    lovCity,
    register
};