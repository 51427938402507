import React from "react";
import { Route, Switch, useHistory, Redirect } from "react-router";
import { Box } from "@material-ui/core";

// Import Component
import HeaderSDI from "./Component/HeaderSDI";

// Import Pages
import Login from "./Pages/Login";
import Error404 from "./Pages/404";
import DashboardStaff from "./Pages/SDI/Staff/Dashboard";
import AlurSeleksiStaff from "./Pages/SDI/Staff/AlurSeleksi";
import DashboardSupervisor from "./Pages/SDI/Supervisor/Dashboard";
import ApprovalPelamar from "./Pages/SDI/Supervisor/ApprovalPelamar";
import RiwayatRekrutmen from "./Pages/SDI/Supervisor/RiwayatRekrutmen";
import DetailPelamar from "./Component/Detail";
import ApprovalDokumen from "./Pages/SDI/Supervisor/ApprovalDokumen";

const PrivateRouteStaff = ({ ...props }) => {
  if (
    localStorage.getItem("isLogin") 
  )
    return <Route {...props} />;
  else {
    localStorage.removeItem("isLogin");
  }

  return <Redirect to="/login" />;
};

const PrivateRouteSupervisor = ({ ...props }) => {
  if (
    localStorage.getItem("isLogin") 
  )
    return <Route {...props} />;
  else {
    localStorage.removeItem("isLogin");
  }

  return <Redirect to="/login" />;
};

const Routes = () => {
  // const classes = useStyles();
  const history = useHistory();

  return (
    <Box>
      {history.location.pathname.includes("/sdi") &&
        localStorage.getItem("isLogin") && <HeaderSDI />}
      <Switch>
        {!localStorage.getItem("isLogin") && (
          <Route exact path="/login" component={Login} />
        )}
        <Route exact path="/">
          <Redirect
            to={
              parseInt(localStorage.getItem("userLevel")) === 6
                ? "/sdi/staff/dashboard"
                : "/sdi/supervisor/dashboard"
            }
            component={
              parseInt(localStorage.getItem("userLevel")) === 6
                ? DashboardStaff
                : DashboardSupervisor
            }
          />
        </Route>
        <PrivateRouteStaff
          path="/sdi/staff/dashboard"
          component={DashboardStaff}
        />
        <PrivateRouteStaff
          path="/sdi/staff/alur-seleksi"
          component={AlurSeleksiStaff}
        />
        <PrivateRouteStaff
          path="/sdi/staff/detail-pelamar/:id"
          component={DetailPelamar}
        />
        <PrivateRouteSupervisor
          path="/sdi/supervisor/dashboard"
          component={DashboardSupervisor}
        />
        <PrivateRouteSupervisor
          path="/sdi/supervisor/approval-pelamar"
          component={ApprovalPelamar}
        />
        <PrivateRouteSupervisor
          path="/sdi/supervisor/approval-dokumen"
          component={ApprovalDokumen}
        />
        <PrivateRouteSupervisor
          path="/sdi/supervisor/riwayat-rekrutment"
          component={RiwayatRekrutmen}
        />
        <PrivateRouteSupervisor
          path="/sdi/supervisor/detail-pelamar/:id"
          component={DetailPelamar}
        />
        <Route component={Error404} />
      </Switch>
    </Box>
  );
};

export default Routes;
