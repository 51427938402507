import React, { useState } from "react";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { ExpandMore, ArrowLeft, ArrowRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  pagination: {
    background: "#F3F4F5",
    border: "1px solid #DEDFE0",
  },
  paginationContent: {
    borderRight: "1px solid #DEDFE0",
    display: "flex",
    justifyContent: "center",
    padding: "0.3vw 0",
  },
  paginationLeft: {
    borderRight: "1px solid #DEDFE0",
    margin: "0.3vw auto",
    cursor: "pointer",
  },
  paginationRight: {
    margin: "0.3vw auto",
    cursor: "pointer",
  },
  paginationText: {
    fontSize: "0.8vw",
    lineHeight: 2.15,
  },
  paginationMenu: {
    display: "flex",
    cursor: "pointer",
  },
  paginationIcon: {
    height: "1.5vw",
    width: "1.5vw",
  },
  footer: {
    padding: "2vw",
    textAlign: "center",
  },
}));

const Pagination = ({ totalItem, size, onChange, pages }) => {
  const classes = useStyles();
  const listItem = [10, 20, 50, 100];
  const [anchorElItem, setAnchorElItem] = useState(null);
  const [anchorElPage, setAnchorElPage] = useState(null);
  const [itemSize, setItemSize] = useState(
    typeof size === "number" ? size : 10
  );
  const [page, setPage] = useState(pages ? pages + 1 : 1);
  const totalPage = Math.ceil(totalItem / itemSize) || 1;

  const handleChange = (sizeValue, pageValue) => {
    const load = {
      size: sizeValue,
      page: pageValue,
    };

    onChange(load);
  };

  const ShowData = () => {
    var firstIndex = totalItem === 0 ? 0 : 1;
    var lastIndex = 1;

    if (page > 1) {
      firstIndex = (page - 1) * itemSize + 1;
    } else {
      firstIndex = totalItem === 0 ? 0 : 1;
    }

    if (page === totalPage) {
      lastIndex = totalItem;
    } else {
      lastIndex = page * itemSize;
    }

    return (
      <Typography className={classes.paginationText}>
        {firstIndex}-{lastIndex} of {totalItem} items
      </Typography>
    );
  };

  const MenuPage = () => {
    const listPage = [];

    for (let i = 1; i <= totalPage; i++) {
      listPage.push(i);
    }

    return (
      <Menu
        keepMounted
        PaperProps={{
          style: { maxHeight: "10vw" },
        }}
        anchorEl={anchorElPage}
        open={Boolean(anchorElPage)}
        onClose={() => setAnchorElPage(null)}
      >
        {listPage.map((row, i) => (
          <MenuItem
            key={i}
            selected={row === page}
            onClick={() => {
              setAnchorElPage(null);
              setPage(row);
              handleChange(itemSize, row);
            }}
          >
            {row}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  return (
    <Box className={classes.footer}>
      <Grid container>
        <Grid item xs={5} />
        <Grid className={classes.pagination} item xs={7}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid className={classes.paginationContent} item xs={4}>
              <Typography className={classes.paginationText}>
                Item per page :
              </Typography>
              <Box
                className={classes.paginationMenu}
                onClick={(e) => {
                  setAnchorElItem(e.currentTarget);
                }}
              >
                <Typography className={classes.paginationText}>
                  {itemSize}
                </Typography>
                <ExpandMore className={classes.paginationIcon} />
              </Box>
              <Menu
                keepMounted
                PaperProps={{
                  style: { maxHeight: "10vw" },
                }}
                anchorEl={anchorElItem}
                open={Boolean(anchorElItem)}
                onClose={() => setAnchorElItem(null)}
              >
                {listItem.map((row, i) => (
                  <MenuItem
                    key={i}
                    selected={row === itemSize}
                    onClick={() => {
                      setAnchorElItem(null);
                      setItemSize(row);
                      setPage(1);
                      handleChange(row, 1);
                    }}
                  >
                    {row}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
            <Grid className={classes.paginationContent} item xs={3}>
              <ShowData />
            </Grid>
            <Grid className={classes.paginationContent} item xs={3}>
              <Box
                className={classes.paginationMenu}
                onClick={(e) => {
                  setAnchorElPage(e.currentTarget);
                }}
              >
                <Typography className={classes.paginationText}>
                  {page}
                </Typography>
                <ExpandMore className={classes.paginationIcon} />
              </Box>
              <Typography className={classes.paginationText}>
                of {totalPage} pages
              </Typography>
              <MenuPage />
            </Grid>
            <Grid className={classes.paginationLeft} item xs={1}>
              <IconButton
                disabled={page === 1}
                onClick={() => {
                  setPage(page - 1);
                  handleChange(itemSize, page - 1);
                }}
                className={classes.paginationIcon}
              >
                <ArrowLeft />
              </IconButton>
            </Grid>
            <Grid className={classes.paginationRight} item xs={1}>
              <IconButton
                disabled={page === totalPage}
                onClick={() => {
                  setPage(page + 1);
                  handleChange(itemSize, page + 1);
                }}
                className={classes.paginationIcon}
              >
                <ArrowRight />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Pagination;
