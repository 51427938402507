import { Chip, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  chipBlue: {
    background: "#CCDFFF",
    color: "#003A99",
    fontSize: "0.625vw",
  },
  chipGreen: {
    background: "#CBE5CC",
    color: "#43A047",
    fontSize: "0.625vw",
  },
  chipRed: {
    background: "#FCCBC8",
    color: "#F44336",
    fontSize: "0.625vw",
  },
  chipOrange: {
    background: "#FDDCB9",
    color: "#CE6B00",
    fontSize: "0.625vw",
  },
  chipBlack: {
    background: "#D2D2D6",
    color: "#5A5B6A",
    fontSize: "0.625vw",
  },
  chipGrey: {
    background: "#DDDDDD",
    color: "#686D76",
    fontSize: "0.625vw",
  },
  chipGrey: {
    background: "#DDDDDD",
    color: "#686D76",
    fontSize: "0.625vw",
  },
}));

const StatusChip = ({ value }) => {
  const classes = useStyles();
  return (
    <>
      {value.toLowerCase() === "disarankan" ||
      value.toLowerCase() === "disarankan dengan pengembangan" ? (
        <Chip className={classes.chipGreen} label={value} variant="outlined" />
      ) : value.toLowerCase() === "potensial" ||
        value.toLowerCase() === "approved" ? (
        <Chip className={classes.chipBlue} label={value} variant="outlined" />
      ) : value.toLowerCase() === "tidak disarankan" ||
        value.toLowerCase() === "rejected" ? (
        <Chip className={classes.chipRed} label={value} variant="outlined" />
      ) : value.toLowerCase() === "blacklist" ? (
        <Chip className={classes.chipBlack} label={value} variant="outlined" />
      ) : value.toLowerCase() === "skip" ? (
        <Chip className={classes.chipOrange} label={value} variant="outlined" />
      ) : value.toLowerCase() === "waiting" ? (
        <Chip className={classes.chipGrey} label={value} variant="outlined" />
      ) : value.toLowerCase() === "waiting puk 1" ? (
        <Chip className={classes.chipGrey} label={value} variant="outlined" />
      ) : value.toLowerCase() === "waiting puk 2" ? (
        <Chip className={classes.chipGrey} label={value} variant="outlined" />
      ) : value.toLowerCase() === "next step" ? (
        <Chip className={classes.chipBlue} label={value} variant="outlined" />
      ) : (
        <Typography>{value}</Typography>
      )}
    </>
  );
};

export default StatusChip;
