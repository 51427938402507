import React from "react";
import {
  makeStyles,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Box,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.1vw",
    fontWeight: "bold",
  },
  content: {
    padding: "3vh 2.5vw",
  },
  accordion: {
    borderRadius: 8,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
  },
  subtitleLabel: {
    color: "#005A7D",
    marginBottom: 8,
    fontSize: "1vw",
  },
  contentLabel: {
    color: "#011627",
    fontWeight: "bold",
    fontSize: "1.15vw",
    overflowWrap:"break-word",
  },
}));

const RiwayatSertifikasi = ({ data }) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        id="detail-riwayat-sertifikasi"
        className={classes.content}
      >
        <Typography className={classes.title}>
          Riwayat Sertifikasi ({data?.length})
        </Typography>
      </AccordionSummary>
      {data?.map((item, index) => (
        <Box key={`riwayat-sertifikasi-${index}`}>
          <Divider />
          <AccordionDetails className={classes.content}>
            <Grid container spacing={5}>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Nama Sertifikat
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.certificationLongName || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Singkatan
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.certificationAbbreviation || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Tanggal Sertifikasi
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.certificationDate
                    ? moment(item.certificationDate).format("DD/MM/YYYY")
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Tanggal Akhir Berlaku
                </Typography>
                <Typography variant="subtitle1" className={classes.contentLabel}>
                  {item ? (
                    item.isForever ? (
                      item.defaultForever
                    ) : (
                      item.certificationExpiredDate
                        ? moment(item.certificationExpiredDate).format("DD/MM/YYYY")
                        : "-"
                    )
                  ) : (
                    "-"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Nomor Sertifikat
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.certificationNumber || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Nama Lembaga
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.certificationInstitutionName || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Predikat
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.certificationPredicate || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.subtitleLabel}
                >
                  Nilai
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.contentLabel}
                >
                  {item.certificationScore || "-"}
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Box>
      ))}
    </Accordion>
  );
};

export default RiwayatSertifikasi;
