import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  makeStyles,
  Box,
  AppBar,
  Toolbar,
  Typography,
  Fab,
  Popper,
  Paper,
  List,
  ListItem,
  ClickAwayListener,
  Tooltip,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: 0,
    justifyContent: "space-between",
    padding: "0.5vw 5vw",
    // [theme.breakpoints.up("md")]: {
    //   padding: "0.5vw 15vw",
    // },
  },
  logo: {
    height: "4vw",
  },
  menu: {
    display: "flex",
  },
  textMenuActive: {
    color: theme.palette.primary.main,
    fontSize: "0.9vw",
    fontWeight: "bold",
    lineHeight: "3vw",
    margin: "0 1vw",
    cursor: "pointer",
  },
  textMenu: {
    color: theme.palette.primary.main,
    fontSize: "0.9vw",
    lineHeight: "3vw",
    margin: "0 1vw",
    cursor: "pointer",
  },
  borderMenu: {
    background: theme.palette.secondary.main,
    height: "0.2vw",
    transform: "translate3d(0px, 0.9vw, 0)",
  },
  buttonLogin: {
    fontSize: "0.9vw",
    padding: "1vw 2.5vw",
    marginRight: "1vw",
  },
  buttonDaftar: {
    fontSize: "0.9vw",
    padding: "1vw 2.5vw",
  },
  profile: {
    width: "3vw",
    height: "3vw",
    minHeight: 0,
    cursor: "pointer",
    boxShadow: "none",
    "&&:active": {
      boxShadow: "none",
    },
  },
  profileIcon: {
    fontSize: "2vw",
    color: theme.palette.background.paper,
  },
  info: {
    padding: "1vw",
    display: "flex",
    borderBottom: "1px solid #DDDDDD",
  },
  infoName: {
    fontSize: "1vw",
    lineHeight: 1.75,
    marginLeft: "1vw",
    minWidth: "8vw",
  },
  infoJabatan: {
    fontSize: "0.8vw",
    fontWeight: "bold",
    marginLeft: "1vw",
  },
  action: {
    padding: "0",
  },
  item: {
    minWidth: "12vw",
  },
  actionText: {
    fontSize: "1vw",
    lineHeight: 3,
  },
  arrow: {
    position: "fixed",
    fontSize: "1vw",
    bottom: "2vw",
    right: "2vw",
    width: "4vw",
    height: "4vw",
    minHeight: 0,
  },
  arrowIcon: {
    fontSize: "2vw",
  },
  Menu: {
    alignItems: "center",
    flexDirection: "row",
    display: "flex",
    gap: 16,
  },
  userText: {
    color: theme.palette.primary.main,
    fontSize: "0.9vw",
    position: "absolute",
    right: "9.375vw",
    width: "15vw",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textAlign: "end",
  },
}));

const Header = () => {
  const classes = useStyles();
  const history = useHistory();

  const menuSupervisor = [
    { title: "Dashboard", url: "/dashboard" },
    { title: "Approval Pelamar", url: "/approval-pelamar" },
    { title: "Approval Dokumen", url: "/approval-dokumen" },
    { title: "Riwayat Rekrutmen", url: "/riwayat-rekrutment" },
  ];
  const menuStaff = [
    { title: "Dashboard", url: "/dashboard" },
    { title: "Alur Seleksi", url: "/alur-seleksi" },
  ];
  const [menuActive, setMenuActive] = useState("/dashboard");
  const [anchorEl, setAnchorEl] = useState({ profile: null });
  const [open, setOpen] = useState({ profile: false });

  useEffect(() => {
    const arrayUrl = history.location.pathname.split("/");
    setMenuActive(`/${arrayUrl[arrayUrl.length - 1]}`);
  }, [history.location.pathname]);

  // if (!Boolean(localStorage.getItem("sdi"))) {
  //   localStorage.setItem("sdi", menuSupervisor[0]);
  // }

  return (
    <AppBar position="fixed" color="inherit">
      <Toolbar className={classes.toolbar} disableGutters>
        <img
          className={classes.logo}
          alt="logo"
          src={process.env.PUBLIC_URL + "/images/logo.png"}
        />
        {history.location.pathname.includes("/supervisor") && (
          <Box className={classes.menu}>
            {menuSupervisor.map((item, i) => (
              <Box
                key={i}
                className={
                  item.url === menuActive
                    ? classes.textMenuActive
                    : classes.textMenu
                }
                onClick={() => {
                  setMenuActive(item.url);
                  history.push(`/sdi/supervisor${item.url}`);
                  // localStorage.setItem("sdi", item);
                }}
              >
                {item.title}
                {item.url === menuActive && (
                  <Box className={classes.borderMenu} />
                )}
              </Box>
            ))}
          </Box>
        )}
        {history.location.pathname.includes("/staff") && (
          <Box className={classes.menu}>
            {menuStaff.map((item, i) => (
              <Box
                key={i}
                className={
                  item.url === menuActive
                    ? classes.textMenuActive
                    : classes.textMenu
                }
                onClick={() => {
                  setMenuActive(item.url);
                  history.push(`/sdi/staff${item.url}`);
                  // localStorage.setItem("sdi", item);
                }}
              >
                {item.title}
                {item.url === menuActive && (
                  <Box className={classes.borderMenu} />
                )}
              </Box>
            ))}
          </Box>
        )}
        <Box className={classes.menu}>
          {open.profile && (
            <ClickAwayListener
              onClickAway={() => {
                setOpen({ profile: false });
              }}
            >
              <Popper
                style={{ zIndex: 10000 }}
                disablePortal
                placement="bottom-end"
                open={open.profile}
                anchorEl={anchorEl.profile}
              >
                <Paper>
                  {/* <Box className={classes.info}>
                    <Fab className={classes.profile} disabled>
                      <Person className={classes.profileIcon} />
                    </Fab>
                    <Box>
                      <Typography className={classes.infoName}>
                        Dwiki Ramdani
                      </Typography>
                      <Typography className={classes.infoJabatan}>
                        {history.location.pathname.includes("/staf")
                          ? "Staf SDI"
                          : "Supervisor SDI"}
                      </Typography>
                    </Box>
                  </Box> */}
                  <List className={classes.action}>
                    {/* {localStorage.getItem("userRole") === "Staff" ? (
                      <ListItem
                        className={classes.item}
                        button
                        onClick={() => {
                          localStorage.setItem("userRole", "Supervisor");
                          setOpen({ profile: false });
                          history.push("/sdi/supervisor/dashboard");
                        }}
                      >
                        <Typography className={classes.actionText}>
                          Change to Supervisor
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem
                        className={classes.item}
                        button
                        onClick={() => {
                          localStorage.setItem("userRole", "Staff");
                          setOpen({ profile: false });
                          history.push("/sdi/staff/dashboard");
                        }}
                      >
                        <Typography className={classes.actionText}>
                          Change to Staff
                        </Typography>
                      </ListItem>
                    )} */}
                    <ListItem
                      className={classes.item}
                      button
                      onClick={() => {
                        localStorage.clear();
                        setOpen({ profile: false });
                        history.push("/");
                      }}
                    >
                      <Typography className={classes.actionText}>
                        Logout
                      </Typography>
                    </ListItem>
                  </List>
                </Paper>
              </Popper>
            </ClickAwayListener>
          )}
          <Box className={classes.Menu}>
            <Tooltip
              title={`${localStorage.getItem(
                "userName"
              )} - ${localStorage.getItem("userRoleName")}`}
              placement="bottom-end"
            >
              <Typography className={classes.userText}>
                <b>{localStorage.getItem("userName")}</b> -{" "}
                {localStorage.getItem("userRoleName")}
              </Typography>
            </Tooltip>
            <Fab
              className={classes.profile}
              onClick={(e) => {
                setAnchorEl({ profile: e.currentTarget });
                setOpen({ profile: true });
              }}
            >
              <Person className={classes.profileIcon} />
            </Fab>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
