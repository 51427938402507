const endPoints = {
  lov: {
    getCategory: {
      method: "get",
      url: "/secured/category/find-all",
    },
    getStatus: {
      method: "get",
      url: "/secured/applicant/lo/status-follow-up",
    },
    getStatusSubmission: {
      method: "get",
      url: "/secured/applicant/lo/status-submission",
    },
    getStatusSubmissionDokumen: {
      method: "get",
      url: "/secured/applicant/lo/status-submission-dokumen",
    },
    getMedia: {
      method: 'get',
      url: '/secured/applicant/lo/media'
    }
  },
  dashboard: {
    getDashboardStaff: {
      method: "get",
      url: "/secured/dashboard",
    },
    getDashboardSupervisor: {
      method: "get",
      url: "/secured/dashboardSPV",
    },
  },
  supervisor: {
    approvePelamar: {
      method: "put",
      url: "/secured/applicant/submission-applicant/approve",
    },
    rejectPelamar: {
      method: "put",
      url: "/secured/applicant/submission-applicant/reject",
    },
    getPelamar: {
      method: "get",
      url: "/secured/applicant/submission-applicant/find-all-SPV",
    },
  },
  pelamar: {
    getPelamar: {
      method: "get",
      url: "/secured/applicant/submission-applicant/find-all",
    },
    getDetailPelamar: {
      method: "get",
      url: "/secured/applicant/detail/:id",
    },
    submitPengajuan: {
      method: "post",
      url: "/secured/applicant/submission-applicant/submission",
    },
    submitTindaklanjuti: {
      method: "post",
      url: "/secured/sdi/selection-flow/follow-up",
    },
    exportFile: {
      method: "get",
      url: "/secured/applicant/submission-applicant/export/excel",
    },
    uploadDokumen: {
      method: "post",
      url: "/secured/uploadDoc?fileType=test-results",
    },
  },
};

export default endPoints;
