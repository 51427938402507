import React from "react";
import {
  makeStyles,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import GlobalFunction from "../../../GlobalFunction";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.1vw",
    fontWeight: "bold",
  },
  content: {
    padding: "3vh 2.5vw",
  },
  accordion: {
    borderRadius: 8,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
  },
  subtitleLabel: {
    color: "#005A7D",
    marginBottom: 8,
    fontSize: "1vw",
  },
  contentLabel: {
    color: "#011627",
    fontWeight: "bold",
    fontSize: "1.15vw",
    overflowWrap:"break-word",
  },
}));

const InformasiLamaranKerja = ({ data, data1 }) => {
  const classes = useStyles();

  return (

    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        id="detail-informasi-lamaran"
        className={classes.content}
      >
        <Typography className={classes.title}>
          Informasi Lamaran Kerja
        </Typography>
      </AccordionSummary>
      <Grid direction="column" container>
        <Divider />
        <AccordionDetails className={classes.content}>
          <Grid container spacing={5}>

          {data1 && data1.length > 0 && (
            <Grid item xs={3}>
              <React.Fragment key={0}>
                <Typography variant="subtitle2" className={classes.subtitleLabel}>
                  Posisi Kerjaaaa
                </Typography>
                <Typography variant="subtitle1" className={classes.contentLabel}>
                  {data1[0].positionName || "-"}
                </Typography>
              </React.Fragment>
            </Grid>
          )}

            {/* <Grid item xs={3}>
              <Typography variant="subtitle2" className={classes.subtitleLabel}>
                Posisi Kerja
              </Typography>
              <Typography variant="subtitle1" className={classes.contentLabel}>
                {data?.positionName || "-"}
              </Typography>
            </Grid> */}

          {data?.length > 0 && (
            <React.Fragment key={0}>
              <Grid item xs={3}>
                <Typography variant="subtitle2" className={classes.subtitleLabel}>
                  Harapan Gaji
                </Typography>
                <Typography variant="subtitle1" className={classes.contentLabel}>
                  {data[0]?.othersExpectSalary
                    ? `Rp ${GlobalFunction.moneyDotFormat(data[0].othersExpectSalary)}`
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2" className={classes.subtitleLabel}>
                  Penempatan yang Diminati
                </Typography>
                <Typography variant="subtitle1" className={classes.contentLabel}>
                  {data[0]?.othersPreferredPlacement || "-"}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2" className={classes.subtitleLabel}>
                  Status Pencari Kerja
                </Typography>
                <Typography variant="subtitle1" className={classes.contentLabel}>
                  {data[0]?.othersJobSeekerStatus || "-"}
                </Typography>
              </Grid>
            </React.Fragment>
          )}
          </Grid>
        </AccordionDetails>
      </Grid>
    </Accordion>
  );
};

export default InformasiLamaranKerja;
