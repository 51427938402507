import { Card, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "1.6vw",
    borderRadius: 8,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "0.9vw",
    fontWeight: 600,
  },
  count: {
    color: theme.palette.secondary.main,
    fontSize: "1.875vw",
    fontWeight: 600,
  },
  status: {
    color: "#808A93",
    fontSize: "0.7vw",
    fontWeight: 600,
  },
}));

const CardDashboard = ({ data }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography className={classes.title}>{data?.title}</Typography>
      <Typography className={classes.count}>
        {data?.totalItems || "0"}
      </Typography>
      <Typography className={classes.status}>{data?.status}</Typography>
    </Card>
  );
};

export default CardDashboard;
