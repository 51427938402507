import React from "react";
import { makeStyles, Box } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5vw 0",
    textAlign: "center",
  },
}));
const NotFound = () => {
  const classes = useStyles();

  return <Box className={classes.root}>NotFound 404</Box>;
};

export default NotFound;
