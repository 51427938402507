import {
  Box,
  Button,
  Card,
  Grid,
  makeStyles,
  MenuItem,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { CloseOutlined, Event } from "@material-ui/icons";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API } from "../../../../../service/axios";
import { Toast } from "../../../../Component/Notification";
import moment from "moment";
import ModalKonfirmasi from "../../ModalKonfirmasi";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "0.9vw",
    fontWeight: "bold",
  },
  contentLabel: {
    color: "black",
    whiteSpace: "pre-wrap",
    fontSize: "0.8vw",
  },
  boxContent: {
    padding: "2.5vw",
  },
  th: {
    fontSize: "0.9vw",
    fontWeight: "bold",
  },
  td: {
    // padding: "2vw",
    fontSize: "0.9vw",
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  buttonBorder: {
    borderRadius: 8,
    backgroundColor: "#FFF1F4",
    padding: "1.2vh 1vw",
    border: "1px solid #E21A43",
    fontSize: "0.7vw",
    fontWeight: 600,
  },
  errorMessage: {
    fontSize: "0.9vw",
    color: theme.palette.secondary.main,
    marginTop: "2vh",
    fontWeight: "bold",
  },
}));

function ModalTindaklanjut({
  visible,
  handleClose,
  onSubmit,
  title,
  data,
  dataStatus,
}) {
  const classes = useStyles();
  const numberValidation = /^[0-9]*$/;
  const alphabetValidation = /^[a-zA-Z ]*$/;
  const letterNumberValidation = /^[a-zA-Z0-9_ ]*$/;
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [modalKonfirmasi, setModalKonfirmasi] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [dataMedia, setDataMedia] = useState([]);

  const uploadDokumen = (file, index) => {
    setLoadingUpload(true);
    const formData = new FormData();
    formData.append("file", file);
    API(`pelamar.uploadDokumen`, { data: formData })
      .then((res) => {
        setLoadingUpload(false);
        if (res.status === 200 && res.data?.Status === "ACCEPTED") {
          formik.setFieldValue(
            `form.${index}.testResultsDocPath`,
            res.data?.FilePath
          );
          formik.setFieldValue(
            `form.${index}.testResultsDocName`,
            res.data?.FileName
          );
          Toast.fire({
            icon: "success",
            title: res.data?.Message || "Upload dokumen berhasil!",
          });
        }
        else {
          Toast.fire({
            icon: "warning",
            title: res.data?.Message || "Upload dokumen gagal, Max File 3MB!",
          });
        }
      })
      .catch((res) => {
        setLoadingUpload(false);
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Upload dokumen gagal, Max File 3MB!",
        });
      });
  };

  const handleConfirmation = () => {
    setIsSubmit(true);
    formik.handleSubmit()
  }

  const handleCloseConfirmation = () =>{
    setModalKonfirmasi(false);
    setIsSubmit(false);
  }

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      form: Yup.array().of(
        Yup.object().shape({
          testResultsStatus: Yup.string().required(),
          testResultsScore: title !== 'Seleksi Administrasi' ? Yup.number().required().min(1).max(5) : null,
          // () =>{
          //  if(title !== 'Seleksi Administrasi'){
          //   return Yup.number().required().min(1).max(5)
          //  }
          // },
          // {title === 'Psikotest & Tes Tulis' & title === 'Wawancara' & title === 'Pemeriksaan Medis (MCU)' & Yup.number().required().min(1).max(5) }
          // testResultsLocation: Yup.string().required(),
          testResultsPic: Yup.string().required(),
          date: Yup.date()
            .typeError("Format tanggal tidak sesuai!")
            .min(new Date(), "Harap isi dengan waktu kedepan!")
            .required("Harap isi tanggal!"),
          // testResultsDocPath: Yup.string().required(),
          // testResultsDocDesc: Yup.string().required(),
          media: Yup.string().required(),
        })
      ),
    }),
    initialValues: { form: [] },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if(isSubmit){
        setSubmitting(true);
        var payload = [];
        values.form.map((item) =>
          payload.push({
            ...item,
            date: moment(item.date).valueOf(),
            time: moment(item.date).valueOf(),
            userId: parseInt(localStorage.getItem("userId")),
          })
        );
        setLoadingSubmit(true);
        API(`pelamar.submitTindaklanjuti`, { data: payload })
          .then((res) => {
            setSubmitting(false);
            setLoadingSubmit(false);
            setIsSubmit(false);
            setModalKonfirmasi(false)
            if (res.status === 200) {
              Toast.fire({
                icon: "success",
                title: res.data?.Message || "Tindaklanjuti berhasil",
              });
              formik.setValues({ form: [] });
              formik.setErrors({});
              formik.setTouched({});
              onSubmit();
            }
          })
          .catch((res) => {
            setSubmitting(false);
            setLoadingSubmit(false);
            setIsSubmit(false);
            setModalKonfirmasi(false)
            Toast.fire({
              icon: "warning",
              title: res.response?.data?.Message || "Tindaklanjuti gagal!",
            });
          });
      }else{
        setModalKonfirmasi(true);
        setSubmitting(false);
      }
    },
  });

  const initialValue = () => {
    var temp = [];

    if (visible) {
      data.map((item) =>
        temp.push({
          testResultsId: item.testResultsId,
          urutan: item.urutan,
          testResultsScore: "",
          testResultsTotalScore: "",
          testResultsDocPath: "",
          testResultsDocName: "",
          testResultsDocDesc: "",
          testResultsStatus: title !== 'Seleksi Administrasi' ? "" : "-",
          testResultsLocation: "",
          testResultsPic: "",
          date: null,
          time: null,
          needApproval: false,
          media: title === 'Seleksi Administrasi' || title === 'Psikotes & Tes Tulis' ? "" : "-",
        })
      );
      formik.setValues({ form: temp });
    } else {
      formik.setValues({ form: [] });
      formik.setErrors({});
      formik.setTouched({});
    }
  };

  const getOptionMedia = useCallback(() => {
    API('lov.getMedia').then(res => {
      if (res?.data?.message === 'success') {
        setDataMedia(res?.data?.data || []);
      }
    })
  }, [])

  useEffect(() => {
    initialValue();
    getOptionMedia();
  }, [visible, getOptionMedia]);

  return (
    <Modal open={visible}>
      <Box className={classes.root}>
        <Card style={{ borderRadius: 8, width: "100%", margin: "2vw" }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: "3.3vh 2.5vw" }}
            >
              <Typography className={classes.title}>
                Tindaklanjuti {title || ""}
              </Typography>
              <CloseOutlined
                style={{ cursor: "pointer", color: "black" }}
                onClick={() => {
                  console.log("formik close", formik.isSubmitting)
                  !formik.isSubmitting && handleClose();
                }}
              />
            </Grid>
            <Box className={classes.boxContent}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.th}>Nama Pelamar</TableCell>
                      {title !== "Seleksi Administrasi" && (<TableCell className={classes.th}>Status</TableCell>)}
                      <TableCell className={classes.th}>Tanggal</TableCell>
                      <TableCell className={classes.th}>Lokasi</TableCell>
                      <TableCell className={classes.th}>PIC</TableCell>
                      {title !== "Seleksi Administrasi" && (<TableCell className={classes.th}> Nilai</TableCell>)}
                      {(title === 'Seleksi Administrasi' || title === 'Psikotes & Tes Tulis') && (
                        <TableCell className={classes.th}>Media</TableCell>
                      )}
                      <TableCell className={classes.th}>
                        Upload Dokumen
                      </TableCell>
                      <TableCell className={classes.th}>Deskripsi</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell className={classes.td}>
                          <Typography>{row.name}</Typography>
                        </TableCell>
                        {title !== "Seleksi Administrasi" && (
                          <TableCell className={classes.td}>
                            <TextField
                              fullWidth
                              hiddenLabel
                              select
                              size="small"
                              variant="filled"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              value={formik.values.form[i]?.testResultsStatus}
                              name={`form.${i}.testResultsStatus`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              SelectProps={{
                                displayEmpty: true,
                              }}
                              error={
                                formik.errors.form &&
                                formik.touched.form &&
                                formik.touched?.form[i]?.testResultsStatus &&
                                formik.errors?.form[i]?.testResultsStatus &&
                                true
                              }
                              helperText={
                                formik.errors.form &&
                                  formik.touched.form &&
                                  formik.errors.form[i]?.testResultsStatus &&
                                  formik.touched.form[i]?.testResultsStatus
                                  ? "Harap pilih status!"
                                  : ""
                              }
                            >
                              <MenuItem key="default-status" value="">
                                Pilih Status
                              </MenuItem>
                              {dataStatus.map((item) => (
                                <MenuItem key={item?.statusId} value={item?.statusName}>
                                  {item?.statusName}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                        )}

                        <TableCell className={classes.td}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                              hiddenLabel
                              fullWidth
                              disablePast
                              size="small"
                              ampm={false}
                              variant="dialog"
                              inputVariant="filled"
                              placeholder="dd/mm/yyyy hh:mm"
                              format="dd/MM/yyyy HH:mm" // This ensures 24-hour format
                              keyboardIcon={<Event className={classes.icon} />}
                              InputProps={{
                                style: { padding: 0 },
                                disableUnderline: true,
                              }}
                              value={formik.values.form[i]?.date}
                              name={`form.${i}.date`}
                              onChange={(val) => {
                                formik.setFieldValue(`form.${i}.date`, val);
                              }}
                              onBlur={formik.handleBlur}
                              error={
                                formik.errors.form &&
                                formik.touched.form &&
                                formik.touched?.form[i]?.date &&
                                formik.errors?.form[i]?.date &&
                                true
                              }
                              helperText={
                                formik.errors.form &&
                                formik.touched.form &&
                                formik.errors.form[i]?.date &&
                                formik.touched.form[i]?.date
                                  ? formik.errors.form[i]?.date
                                  : ""
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </TableCell>

                        <TableCell className={classes.td}>
                          <TextField
                            fullWidth
                            hiddenLabel
                            size="small"
                            variant="filled"
                            placeholder="Masukkan lokasi"
                            autoComplete="off"
                            value={formik.values.form[i]?.testResultsLocation}
                            name={`form.${i}.testResultsLocation`}
                            onChange={(e) => formik.handleChange(e)}
                            onBlur={formik.handleBlur}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            error={
                              formik.errors.form &&
                              formik.touched.form &&
                              formik.touched?.form[i]?.testResultsLocation &&
                              formik.errors?.form[i]?.testResultsLocation &&
                              true
                            }
                            helperText={
                              formik.errors.form &&
                                formik.touched.form &&
                                formik.errors.form[i]?.testResultsLocation &&
                                formik.touched.form[i]?.testResultsLocation
                                ? "Harap isi lokasi!"
                                : ""
                            }
                          />
                        </TableCell>
                        <TableCell className={classes.td}>
                          <TextField
                            fullWidth
                            hiddenLabel
                            size="small"
                            variant="filled"
                            placeholder="Masukkan nama PIC"
                            autoComplete="off"
                            value={formik.values.form[i]?.testResultsPic}
                            name={`form.${i}.testResultsPic`}
                            onChange={(e) =>
                              letterNumberValidation.test(e.target.value) &&
                              formik.handleChange(e)
                            }
                            onBlur={formik.handleBlur}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            error={
                              formik.errors.form &&
                              formik.touched.form &&
                              formik.touched?.form[i]?.testResultsPic &&
                              formik.errors?.form[i]?.testResultsPic &&
                              true
                            }
                            helperText={
                              formik.errors.form &&
                                formik.touched.form &&
                                formik.errors.form[i]?.testResultsPic &&
                                formik.touched.form[i]?.testResultsPic
                                ? "Harap isi nama PIC!"
                                : ""
                            }
                          />
                        </TableCell>
                        {title !== "Seleksi Administrasi" && (
                          <TableCell className={classes.td}>
                            <TextField
                              fullWidth
                              hiddenLabel
                              size="small"
                              variant="filled"
                              placeholder="Masukkan nilai"
                              autoComplete="off"
                              value={formik.values.form[i]?.testResultsScore}
                              name={`form.${i}.testResultsScore`}
                              onChange={(e) =>
                                numberValidation.test(e.target.value) &&
                                formik.handleChange(e)
                              }
                              onBlur={formik.handleBlur}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              error={
                                formik.errors.form &&
                                formik.touched.form &&
                                formik.touched?.form[i]?.testResultsScore &&
                                formik.errors?.form[i]?.testResultsScore &&
                                true
                              }
                              helperText={
                                formik.errors.form &&
                                  formik.touched.form &&
                                  formik.errors.form[i]?.testResultsScore &&
                                  formik.touched.form[i]?.testResultsScore
                                  ? "Harap isi nilai 1 - 5!"
                                  : ""
                              }
                            />
                          </TableCell>)}
                        {(title === 'Seleksi Administrasi' || title === 'Psikotes & Tes Tulis') && (
                          <TableCell className={classes.td}>
                            <TextField
                              fullWidth
                              hiddenLabel
                              select
                              size="small"
                              variant="filled"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              value={formik.values.form[i]?.media}
                              name={`form.${i}.media`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              SelectProps={{
                                displayEmpty: true,
                              }}
                              error={
                                formik.errors.form &&
                                formik.touched.form &&
                                formik.touched?.form[i]?.media &&
                                formik.errors?.form[i]?.media &&
                                true
                              }
                              helperText={
                                formik.errors.form &&
                                  formik.touched.form &&
                                  formik.errors.form[i]?.media &&
                                  formik.touched.form[i]?.media
                                  ? "Harap pilih media"
                                  : ""
                              }
                            >
                              <MenuItem key="default-status" value="">
                                Pilih Media
                              </MenuItem>
                              {dataMedia.map(item => (
                                <MenuItem key={item?.statusId} value={item?.statusName}>{item?.statusName}</MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                        )}
                        <TableCell className={classes.td}>
                          <Grid
                            container
                            justifyContent="flex-start"
                            spacing={1}
                            alignItems="center"
                          >
                            <Grid item>
                              <input
                                accept="application/pdf"
                                style={{ display: "none" }}
                                id="upload-file-test-result"
                                type="file"
                                onChange={(val) =>
                                  uploadDokumen(val.target.files[0], i)
                                }
                              />
                              <label htmlFor="upload-file-test-result">
                                <Button
                                  color="secondary"
                                  component="span"
                                  className={classes.buttonBorder}
                                  disabled={loadingUpload}
                                >
                                  {loadingUpload ? (
                                    <i className="fas fa-circle-notch fa-spin" />
                                  ) : (
                                    ""
                                  )}
                                  Upload
                                </Button>
                              </label>
                            </Grid>
                            <Grid item>
                              <Typography>
                                {formik.values.form[i]?.testResultsDocName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell className={classes.td}>
                          <TextField
                            fullWidth
                            hiddenLabel
                            size="small"
                            variant="filled"
                            autoComplete="off"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            placeholder="Masukan deskripsi"
                            value={formik.values.form[i]?.testResultsDocDesc}
                            name={`form.${i}.testResultsDocDesc`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* {formik.errors.form && formik.touched.form && (
                <Typography className={classes.errorMessage}>
                  Harap pilih/isi data status, tanggal dan nilai
                </Typography>
              )} */}
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              style={{ padding: "3.3vh 2.5vw" }}
              spacing={2}
            >
              <Grid item>
                <Button
                  color="secondary"
                  className={classes.buttonBorder}
                  onClick={handleClose}
                  disabled={formik.isSubmitting}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    borderRadius: 8,
                    padding: "1.2vh 2.5vw",
                    fontSize: "0.7vw",
                    fontWeight: 600,
                  }}
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <i className="fas fa-circle-notch fa-spin" />
                  ) : (
                    ""
                  )}
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
          <ModalKonfirmasi
            visible={modalKonfirmasi}
            handleClose={() => handleCloseConfirmation()}
            onSubmit={() => handleConfirmation()}
            loading={loadingSubmit}
          />
        </Card>
      </Box>
    </Modal>
  );
}

export default ModalTindaklanjut;
