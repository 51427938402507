import React from "react";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  Divider,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import StatusChip from "../../../GlobalComponent/StatusChip";
import { config } from "../../../config";

const useStyles = makeStyles((theme) => ({
  titleCard: {
    color: theme.palette.primary.main,
    fontSize: "1.1vw",
    fontWeight: "bold",
  },
  content: {
    padding: "3vh 2.5vw",
  },
  accordion: {
    borderRadius: 8,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
  },
  subtitleLabel: {
    color: "#005A7D",
    marginBottom: 8,
    fontSize: "1vw",
  },
  contentLabel: {
    color: "#011627",
    fontWeight: "bold",
    fontSize: "1.15vw",
    overflowWrap:"break-word",
  },
  titleSection: {
    color: "#005A7D",
    fontWeight: "bold",
    fontSize: "0.9vw",
  },
}));

const HasilRecruitment = ({ data }) => {
  const classes = useStyles();
  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        id="detail-hasil-rekrutmen"
        className={classes.content}
      >
        <Typography className={classes.titleCard}>
          Hasil Rekapan Rekrutmen
        </Typography>
      </AccordionSummary>
      <Grid direction="column" container>
        {data?.map((item, index) => (
          <Box key={`detail-${index}`}>
            <Divider />
            <AccordionDetails className={classes.content}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography className={classes.titleSection}>
                    {item.stepSelectionName}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    Status
                  </Typography>
                  <StatusChip value={item.testResultsStatus || "-"} />
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    Tanggal
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.contentLabel}
                  >
                     {item.selectionDay ? new Date(item.selectionDay).toLocaleDateString() : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    Lokasi
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.contentLabel}
                  >
                    {item.location || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    PIC
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.contentLabel}
                  >
                    {item.pic || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    Nilai
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.contentLabel}
                  >
                    {item.testResultsScore || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    Dokumen Terlampir
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.contentLabel}
                    style={item.testResultsDocPath ? { cursor: "pointer" } : {}}
                    onClick={() => {
                      if (item.testResultsDocPath) {
                        window.open(
                          `${config.BASE_URL}${item.testResultsDocPath}`,
                          "_blank"
                        );
                      }
                    }}
                  >
                    {item.testResultsDocName || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="subtitle2"
                    className={classes.subtitleLabel}
                  >
                    Deskripsi Dokumen
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.contentLabel}
                  >
                    {item.testResultsDocDesc || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Box>
        ))}
      </Grid>
    </Accordion>
  );
};

export default HasilRecruitment;
