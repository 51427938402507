import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  makeStyles,
  Box,
  Grid,
  Card,
  Paper,
  Typography,
  Radio,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import { ArrowBackIosRounded } from "@material-ui/icons";
import IdentitasDiri from "./IdentitasDiri";
import RiwayatPendidikan from "./RiwayatPendidikan";
import RiwayatOrganisasi from "./RiwayatOrganisasi";
import RiwayatPekerjaan from "./RiwayatPekerjaan";
import RiwayatKursus from "./RiwayatKursus";
import RiwayatSertifikasi from "./RiwayatSertifikasi";
import InformasiLamaranKerja from "./InformasiLamaranKerja";
import { Toast } from "../Notification";
import { API } from "../../../service/axios";
import HasilRecruitment from "./hasilRekrutmen";
import { config } from "../../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "12.5vh 5vw",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.25vw",
    fontWeight: "bold",
  },
  subtitle: {
    color: "#011627",
    fontSize: "1vw",
  },
  circleIcon: {
    backgroundColor: "#E21A43",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 6,
    borderRadius: "50%",
    width: 28,
    height: 28,
    cursor: "pointer",
  },
  iconBack: {
    color: "#FCFCFC",
    fontSize: 16,
  },
  radioLabel: {
    color: "#011627",
    fontSize: "1vw",
    fontWeight: "bold",
  },
  avatar: { width: 96, height: 96 },
  card: {
    padding: "5vh 2vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
  },
}));

const DetailPelamar = () => {
  const classes = useStyles();
  const history = useHistory();
  const [dataDetail, setDataDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = () => {
    setLoading(true);
    API(`pelamar.getDetailPelamar`, {
      query: { id },
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200 && res.data?.message === "success") {
          setDataDetail(res.data.data);
        }
      })
      .catch((res) => {
        setLoading(false);
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Gagal mendapat data detail!",
        });
      });
  };

  const listIdentitas = [
    "Identitas Diri",
    "Riwayat Pendidikan Terakhir",
    "Riwayat Organisasi",
    "Riwayat Pekerjaan",
    "Riwayat Kursus",
    "Riwayat Sertifikasi",
    "Informasi Lamaran Kerja",
  ];

  return (
    <Box className={classes.root}>
      <Grid spacing={4} container>
        <Grid container spacing={3} item>
          <Grid item>
            <Paper
              onClick={() => {
                history.goBack();
              }}
              className={classes.circleIcon}
            >
              <ArrowBackIosRounded className={classes.iconBack} />
            </Paper>
          </Grid>
          <Grid item>
            <Typography className={classes.title}>Detail Profile</Typography>
          </Grid>
        </Grid>
        {loading ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: "30vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container item spacing={4}>
            <Grid container spacing={5} direction="column" item xs={9}>
              <Grid item>
                <HasilRecruitment data={dataDetail?.testResultsDtos} />
              </Grid>
              <Grid item>
                <IdentitasDiri data={dataDetail?.identityDto} />
              </Grid>
              <Grid item>
                <RiwayatPendidikan
                  data={dataDetail?.eduCustomDto}
                />
              </Grid>
              <Grid item>
                <RiwayatOrganisasi
                  data={dataDetail?.orgCustomDto}
                />
              </Grid>
              <Grid item>
                <RiwayatPekerjaan
                  data={dataDetail?.workCustomDto}
                />
              </Grid>
              <Grid item>
                <RiwayatKursus
                  data={dataDetail?.courseCustomDto}
                />
              </Grid>
              <Grid item>
                <RiwayatSertifikasi
                  data={dataDetail?.certificationCustomDto}
                />
              </Grid>
              <Grid item>
                <InformasiLamaranKerja
                  data={dataDetail?.profileDto?.submissionFormCustomDto}
                  data1={dataDetail?.testResultsDtos}
                />
              </Grid>
            </Grid>
            <Grid direction="column" container item xs={3} spacing={3}>
              <Grid item>
                <Card className={classes.card}>
                  <Avatar
                    className={classes.avatar}
                    src={`${config.BASE_URL}${dataDetail?.profileDto?.applicantPassPhotoPath}` || ""}
                  />
                  <Grid
                    justifyContent="center"
                    direction="column"
                    alignItems="center"
                    container
                  >
                   
                    <Grid item>
                      <Typography className={classes.title}>
                      {Array.isArray(dataDetail?.identityDto) && dataDetail.identityDto.length > 0 
                        ? dataDetail.identityDto[0].applicantName 
                        : "-"
                      }

                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.subtitle}>
                        {dataDetail?.profileDto?.applicantEmail || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid container direction="column" item>
                {listIdentitas.map((item, index) => (
                  <Grid
                    key={`radio-${index}`}
                    container
                    alignItems="center"
                    spacing={1}
                    item
                  >
                    <Grid item>
                      <Radio checked value={item} />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.radioLabel}>
                        {item}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default DetailPelamar;
