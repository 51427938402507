import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { API } from "../../../../service/axios";
import { Toast } from "../../../Component/Notification";
import ModalKonfirmasi from "../ModalKonfirmasi";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "23vw",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "0.9vw",
    fontWeight: "bold",
  },
  contentLabel: {
    color: "black",
    whiteSpace: "pre-wrap",
    fontSize: "0.8vw",
  },
  boxContent: {
    padding: "2.5vw",
  },
}));

function ModalApproval({ visible, handleClose, onSubmit, type, title, data }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [modalKonfirmasi, setModalKonfirmasi] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleConfirmation = () => {
    if(isSubmit){
      if (title === "Approval") {
        approvalPelamar();
      } else {
        rejectPelamar();
      }
    }else{
      setModalKonfirmasi(true);
      setIsSubmit(true);
    }
  }

  const handleCloseConfirmation = () => {
    setModalKonfirmasi(false);
    setIsSubmit(false);
  }

  const approvalPelamar = () => {
    setLoading(true);
    API(`supervisor.approvePelamar`, {
      params: { testResultsId: data, urutan: type === "pelamar" ? 1 : 6 },
      data: {
        userId: localStorage.getItem("userId"),
        levelPangkatId: parseInt(localStorage.getItem("userLevel")),
      },
    })
      .then((res) => {
        setLoading(false);
        setModalKonfirmasi(false);
        if (res.status === 200) {
          Toast.fire({
            icon: "success",
            title: res.response?.data?.Message || "Berhasil melakukan approve!",
          });
          onSubmit();
        }
      })
      .catch((res) => {
        setLoading(false);
        setModalKonfirmasi(false);
        Toast.fire({
          icon: "warning",
          title: res.data?.Message || "Gagal melakukan approve!",
        });
      });
  };

  const rejectPelamar = () => {
    setLoading(true);
    API(`supervisor.rejectPelamar`, {
      params: { testResultsId: data, urutan: type === "pelamar" ? 1 : 6 },
      data: {
        userId: parseInt(localStorage.getItem("userId")),
        levelPangkatId: parseInt(localStorage.getItem("userLevel")),
      },
    })
      .then((res) => {
        setLoading(false);
        setModalKonfirmasi(false);
        if (res.status === 200) {
          Toast.fire({
            icon: "success",
            title: res.data?.Message || "Berhasil melakukan reject!",
          });
          onSubmit();
        }
      })
      .catch((res) => {
        setLoading(false);
        setModalKonfirmasi(false);
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || "Gagal melakukan reject!",
        });
      });
  };

  return (
    <Modal open={visible}>
      <Box className={classes.root}>
        <Card style={{ borderRadius: 8 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ padding: "3.3vh 2.5vw" }}
          >
            <Typography className={classes.title}>
              Konfirmasi {title}
            </Typography>
            <CloseOutlined
              style={{ cursor: "pointer", color: "black" }}
              onClick={() => {
                !loading && handleClose();
              }}
            />
          </Grid>
          <Divider />
          <Box className={classes.boxContent}>
            <Typography className={classes.contentLabel}>
              Apakah Anda yakin akan{" "}
              {title === "Approval" ? "approve" : "reject"}
              {type === "dokumen" && " dokumen"} pelamar ini?
            </Typography>
          </Box>
          <Divider />
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ padding: "3.3vh 2.5vw" }}
            spacing={2}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                color="secondary"
                style={{
                  borderRadius: 8,
                  backgroundColor: "#FFF1F4",
                  padding: "1.2vh 1vw",
                  border: "1px solid #E21A43",
                  fontSize: "0.7vw",
                  fontWeight: 600,
                }}
                onClick={handleClose}
                disabled={loading}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                style={{
                  borderRadius: 8,
                  padding: "1.2vh 2.5vw",
                  fontSize: "0.7vw",
                  fontWeight: 600,
                }}
                onClick={() => {
                  handleConfirmation()
                }}
                disabled={loading}
              >
                {loading ? <i className="fas fa-circle-notch fa-spin" /> : ""}
                {title === "Approval" ? "Approve" : "Reject"}
              </Button>
            </Grid>
          </Grid>
          <ModalKonfirmasi
            visible={modalKonfirmasi}
            handleClose={() => handleCloseConfirmation()}
            onSubmit={() =>handleConfirmation()}
            loading={loading}
          />
        </Card>
      </Box>
    </Modal>
  );
}

export default ModalApproval;
