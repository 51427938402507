export const SET_CURRENT_TAB_STAFF = "SET_CURRENT_TAB_STAFF";
export const SET_CURRENT_TAB_SPV = "SET_CURRENT_TAB_SPV";

export function setCurrentTabStaff(value) {
  return {
    type: SET_CURRENT_TAB_STAFF,
    value,
  };
}

export function setCurrentTabSpv(value) {
  return {
    type: SET_CURRENT_TAB_SPV,
    value,
  };
}
